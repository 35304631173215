<template>
  <span :class="['product-price', { 'product-price--small': smallPrice }]">
    <template v-if="showFree && isFree">
      <span data-nw="order-summary-free" class="fw-bold">{{
        $label('common.gratis')
      }}</span>
    </template>
    <template v-else>
      <template v-if="hasDiscount">
        <span class="td-l sale-price">{{ originalPriceWithCurrency }}</span>
        <span class="discount-price" data-testid="final-price">
          &nbsp;{{ discountPriceWithCurrency }}
        </span>
      </template>
      <template v-else>
        <span class="price" data-testid="final-price">
          {{ originalPriceWithCurrency }}
        </span>
      </template>
    </template>
  </span>
</template>

<script>
import currencyFormatter from '~/utils/currencyFormatter'

export default {
  props: {
    originalPrice: {
      type: Number,
      required: true,
    },
    discountPrice: {
      type: Number,
      default: null,
    },
    showFree: {
      type: Boolean,
      default: false,
    },
    smallPrice: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isFree() {
      return (
        this.discountPrice === 0 && this.discountPrice === this.originalPrice
      )
    },
    hasDiscount() {
      return (
        this.discountPrice !== null && this.discountPrice < this.originalPrice
      )
    },
    originalPriceWithCurrency() {
      return currencyFormatter(this.originalPrice)
    },
    discountPriceWithCurrency() {
      return currencyFormatter(this.discountPrice)
    },
  },
}
</script>

<style lang="scss" scoped>
.price,
.discount-price {
  font-size: 18px;
  font-weight: $font-weight-bold;
}

.sale-price {
  color: $grey-400;
  margin-right: 4px;
}

.discount-price {
  color: $orange-700;
}

.product-price--small {
  .price,
  .discount-price {
    font-size: 16px;
  }

  .sale-price {
    font-size: 14px;
    margin-right: 0;
  }
}
</style>
